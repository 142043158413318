import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { SET_USER, SET_SHOW_UPSELL } from './mutation-types'

const STORAGE_HASH = 'LzDaGKmFEY'
export const STORAGE_KEY = `grainfertility-${STORAGE_HASH}`

const userModule = {
  state: { user: null },
  mutations: {
    [SET_USER]: (state, payload) => {
      state.user = payload
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit(SET_USER, user)
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.user
    },
    user: (state) => {
      return state.user
    },
    token: (state) => {
      return state ? state.user.token : null
    },
  },
}

const upsellModule = {
  state: { showUpsell: false },
  mutations: {
    [SET_SHOW_UPSELL]: (state, payload) => {
      state.showUpsell = payload
    },
  },
  actions: {
    setShowUpsell({ commit, rootState }, showUpsell) {
      if (rootState.user?.user && !rootState.user?.user?.isSubscribed) {
        commit(SET_SHOW_UPSELL, showUpsell)
      }
    },
  },
  getters: {
    showUpsell: (state) => {
      return state.showUpsell
    },
  },
}

const store = createStore({
  modules: {
    user: userModule,
    upsell: upsellModule,
  },
  plugins: [
    createPersistedState({
      key: STORAGE_KEY,
      paths: ['user'],
    }),
  ],
})

export default store
