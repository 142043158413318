export const TRANSITIONS = {
  overlay: {
    enterFromClass: 'opacity-0 scale-75',
    enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
    leaveActiveClass: 'transition-opacity duration-150 ease-linear',
    leaveToClass: 'opacity-0',
  },
}

export default {
  accordiontab: {
    header: ({ props }) => ({
      class: [
        // Sizing
        'p-3',
        'mt-3',

        // Shape
        '',

        // Color
        'bg-bgGray hover:bg-lightGray',
        'border border-surface-200 dark:border-surface-700',

        // State
        { 'select-none pointer-events-none cursor-default opacity-60': props?.disabled },
      ],
    }),
    headerAction: ({ context }) => ({
      class: [
        //Font
        'font-semibold',
        'leading-7',

        // Alignments
        'flex items-center justify-between flex-row-reverse',
        'relative',

        // Shape
        'rounded-md',

        // Color
        'text-surface-900 dark:text-surface-0',

        // States
        'focus:outline-none focus:outline-offset-0 focus-visible:ring-2 focus-visible:ring-primary-600 ring-inset dark:focus-visible:ring-primary-500', // Focus

        // Misc
        'cursor-pointer no-underline select-none',
      ],
    }),
    headerIcon: {
      class: 'inline-block ml-2',
    },
    headerTitle: {
      class: 'leading-7',
    },
    content: {
      class: [
        // Font
        'leading-7',

        // Spacing
        'pr-12 pt-2 p-2',
        'border border-surface-200 dark:border-surface-700 border-t-0',

        // Color
        'text-surface-600 dark:text-surface-0/70',
      ],
    },
    transition: {
      enterFromClass: 'max-h-0',
      enterActiveClass:
        'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
      enterToClass: 'max-h-[1000px]',
      leaveFromClass: 'max-h-[1000px]',
      leaveActiveClass:
        'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
      leaveToClass: 'max-h-0',
    },
  },
  autocomplete: {
    root: ({ props }) => ({
      class: [
        'relative inline-flex',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
        { 'w-full': props.multiple },
      ],
    }),
    container: {
      class: [
        'm-0 list-none cursor-text overflow-hidden flex items-center flex-wrap w-full',
        'px-3 py-2 gap-2',
        'font-sans text-base text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-300 dark:border-blue-900/40  transition duration-200 ease-in-out appearance-none rounded-md',
        'focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] hover:border-blue-500 focus:outline-none dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
      ],
    },
    inputtoken: {
      class: ['py-0.375rem px-0', 'flex-1 inline-flex'],
    },
    input: ({ props }) => ({
      class: [
        'm-0',
        ' transition-colors duration-200 appearance-none rounded-lg',
        { 'rounded-tr-none rounded-br-none': props.dropdown },
        {
          'font-sans text-base text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 p-3 border border-gray-300 dark:border-blue-900/40 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)] hover:border-blue-500 focus:outline-none':
            !props.multiple,
          'font-sans text-base text-gray-700 dark:text-white/80 border-0 outline-none bg-transparent m-0 p-0 shadow-none rounded-none w-full':
            props.multiple,
        },
      ],
    }),
    removeTokenIcon: {
      class: ['ml-1'],
    },
    token: {
      class: [
        'py-1 px-2 mr-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white/80 rounded-full',
        'cursor-default inline-flex items-center',
      ],
    },
    dropdownbutton: {
      root: 'rounded-tl-none rounded-bl-none',
    },
    panel: {
      class: [
        'bg-white text-gray-700 border-0 rounded-md shadow-lg',
        'max-h-[200px] overflow-auto',
        'dark:bg-gray-900 dark:text-white/80',
      ],
    },
    list: 'py-3 list-none m-0',
    item: ({ context }) => ({
      class: [
        'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
        'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
        'dark:text-white/80 dark:hover:bg-gray-800',
        'hover:text-gray-700 hover:bg-gray-200',
        {
          'text-gray-700': !context.focused && !context.selected,
          'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90':
            context.focused && !context.selected,
          'bg-blue-500 text-blue-700 dark:bg-blue-400 dark:text-white/80':
            context.focused && context.selected,
          'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80':
            !context.focused && context.selected,
        },
      ],
    }),
    itemgroup: {
      class: [
        'm-0 p-3 text-gray-800 bg-white font-bold',
        'dark:bg-gray-900 dark:text-white/80',
        'cursor-auto',
      ],
    },
    transition: TRANSITIONS.overlay,
  },
  button: {
    root: ({ props, context, parent }) => ({
      class: [
        'relative',

        // Alignments
        'items-center justify-center inline-flex text-center align-bottom',

        // Sizes & Spacing
        'text-sm',
        {
          'px-2.5 py-1.5 min-w-[2rem]': props.size === null && props.label !== null,
          'px-2 py-1': props.size === 'small',
          'px-3 py-2': props.size === 'large',
        },
        {
          'h-8 w-8 p-0': props.label == null,
        },

        // Shapes
        { 'shadow-sm': !props.raised && !props.link && !props.text, 'shadow-lg': props.raised },
        { 'rounded-md': !props.rounded, 'rounded-full': props.rounded },
        {
          'rounded-none first:rounded-l-md last:rounded-r-md self-center':
            parent.instance?.$name == 'InputGroup',
        },

        // Link Button
        { 'text-primary-600 bg-transparent ring-transparent': props.link },

        // Plain Button
        {
          'bg-gray-500 ring-1 ring-gray-500': props.plain && !props.outlined && !props.text,
        },
        // Plain Text Button
        { 'text-surface-500': props.plain && props.text },
        // Plain Outlined Button
        { 'text-surface-500 ring-1 ring-gray-500': props.plain && props.outlined },

        // Text Button
        { 'bg-transparent ring-transparent': props.text && !props.plain },

        // Outlined Button
        { 'bg-transparent': props.outlined && !props.plain },

        // --- Severity Buttons ---

        // Primary Button
        {
          'dark:text-surface-900':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'bg-primary-500 dark:bg-primary-400':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          'ring-1 ring-primary-500 dark:ring-primary-400':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        // Primary Text Button
        {
          'text-primary-500 dark:text-primary-400':
            props.text && props.severity === null && !props.plain,
        },
        // Primary Outlined Button
        {
          'text-primary-500 ring-1 ring-primary-500 hover:bg-primary-300/20':
            props.outlined && props.severity === null && !props.plain,
        },

        // Secondary Button
        {
          'dark:text-surface-900':
            props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
          'bg-surface-500 dark:bg-surface-400':
            props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
          'ring-1 ring-surface-500 dark:ring-surface-400':
            props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
        },
        // Secondary Text Button
        {
          'text-surface-500 dark:text-surface-400':
            props.text && props.severity === 'secondary' && !props.plain,
        },
        // Secondary Outlined Button
        {
          'text-surface-500 ring-1 ring-surface-500 hover:bg-surface-300/20':
            props.outlined && props.severity === 'secondary' && !props.plain,
        },

        // Success Button
        {
          'dark:text-green-900':
            props.severity === 'success' && !props.text && !props.outlined && !props.plain,
          'bg-green-500 dark:bg-green-400':
            props.severity === 'success' && !props.text && !props.outlined && !props.plain,
          'ring-1 ring-green-500 dark:ring-green-400':
            props.severity === 'success' && !props.text && !props.outlined && !props.plain,
        },
        // Success Text Button
        {
          'text-green-500 dark:text-green-400':
            props.text && props.severity === 'success' && !props.plain,
        },
        // Success Outlined Button
        {
          'text-green-500 ring-1 ring-green-500 hover:bg-green-300/20':
            props.outlined && props.severity === 'success' && !props.plain,
        },

        // Info Button
        {
          'dark:text-surface-900':
            props.severity === 'info' && !props.text && !props.outlined && !props.plain,
          'bg-blue-500 dark:bg-blue-400':
            props.severity === 'info' && !props.text && !props.outlined && !props.plain,
          'ring-1 ring-blue-500 dark:ring-blue-400':
            props.severity === 'info' && !props.text && !props.outlined && !props.plain,
        },
        // Info Text Button
        {
          'text-blue-500 dark:text-blue-400':
            props.text && props.severity === 'info' && !props.plain,
        },
        // Info Outlined Button
        {
          'text-blue-500 ring-1 ring-blue-500 hover:bg-blue-300/20 ':
            props.outlined && props.severity === 'info' && !props.plain,
        },

        // Warning Button
        {
          'dark:text-surface-900':
            props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
          'bg-orange-500 dark:bg-orange-400':
            props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
          'ring-1 ring-orange-500 dark:ring-orange-400':
            props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
        },
        // Warning Text Button
        {
          'text-orange-500 dark:text-orange-400':
            props.text && props.severity === 'warning' && !props.plain,
        },
        // Warning Outlined Button
        {
          'text-orange-500 ring-1 ring-orange-500 hover:bg-orange-300/20':
            props.outlined && props.severity === 'warning' && !props.plain,
        },

        // Help Button
        {
          'dark:text-surface-900':
            props.severity === 'help' && !props.text && !props.outlined && !props.plain,
          'bg-purple-500 dark:bg-purple-400':
            props.severity === 'help' && !props.text && !props.outlined && !props.plain,
          'ring-1 ring-purple-500 dark:ring-purple-400':
            props.severity === 'help' && !props.text && !props.outlined && !props.plain,
        },
        // Help Text Button
        {
          'text-purple-500 dark:text-purple-400':
            props.text && props.severity === 'help' && !props.plain,
        },
        // Help Outlined Button
        {
          'text-purple-500 ring-1 ring-purple-500 hover:bg-purple-300/20':
            props.outlined && props.severity === 'help' && !props.plain,
        },

        // Danger Button
        {
          'dark:text-surface-900':
            props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
          'bg-red-500 dark:bg-red-400':
            props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
          'ring-1 ring-red-500 dark:ring-red-400':
            props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
        },
        // Danger Text Button
        {
          'text-red-500 dark:text-red-400':
            props.text && props.severity === 'danger' && !props.plain,
        },
        // Danger Outlined Button
        {
          'text-red-500 ring-1 ring-red-500 hover:bg-red-300/20':
            props.outlined && props.severity === 'danger' && !props.plain,
        },

        // --- Severity Button States ---
        'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
        { 'focus:ring-offset-2': !props.link && !props.plain && !props.outlined && !props.text },

        // Link
        { 'focus:ring-primary-500 dark:focus:ring-primary-400': props.link },

        // Plain
        { 'hover:bg-gray-600 hover:ring-gray-600': props.plain && !props.outlined && !props.text },
        // Text & Outlined Button
        { 'hover:bg-surface-300/20': props.plain && (props.text || props.outlined) },

        // Primary
        {
          'hover:bg-primary-600 dark:hover:bg-primary-300 hover:ring-primary-600 dark:hover:ring-primary-300':
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        { 'focus:ring-primary-500 dark:focus:ring-primary-400': props.severity === null },
        // Text & Outlined Button
        {
          'hover:bg-primary-300/20':
            (props.text || props.outlined) && props.severity === null && !props.plain,
        },

        // Secondary
        {
          'hover:bg-surface-600 dark:hover:bg-surface-300 hover:ring-surface-600 dark:hover:ring-surface-300':
            props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
        },
        { 'focus:ring-surface-500 dark:focus:ring-surface-400': props.severity === 'secondary' },
        // Text & Outlined Button
        {
          'hover:bg-surface-300/20':
            (props.text || props.outlined) && props.severity === 'secondary' && !props.plain,
        },

        // Success
        {
          'hover:bg-green-600 dark:hover:bg-green-300 hover:ring-green-600 dark:hover:ring-green-300':
            props.severity === 'success' && !props.text && !props.outlined && !props.plain,
        },
        { 'focus:ring-green-500 dark:focus:ring-green-400': props.severity === 'success' },
        // Text & Outlined Button
        {
          'hover:bg-green-300/20':
            (props.text || props.outlined) && props.severity === 'success' && !props.plain,
        },

        // Info
        {
          'hover:bg-blue-600 dark:hover:bg-blue-300 hover:ring-blue-600 dark:hover:ring-blue-300':
            props.severity === 'info' && !props.text && !props.outlined && !props.plain,
        },
        { 'focus:ring-blue-500 dark:focus:ring-blue-400': props.severity === 'info' },
        // Text & Outlined Button
        {
          'hover:bg-blue-300/20':
            (props.text || props.outlined) && props.severity === 'info' && !props.plain,
        },

        // Warning
        {
          'hover:bg-orange-600 dark:hover:bg-orange-300 hover:ring-orange-600 dark:hover:ring-orange-300':
            props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
        },
        { 'focus:ring-orange-500 dark:focus:ring-orange-400': props.severity === 'warning' },
        // Text & Outlined Button
        {
          'hover:bg-orange-300/20':
            (props.text || props.outlined) && props.severity === 'warning' && !props.plain,
        },

        // Help
        {
          'hover:bg-purple-600 dark:hover:bg-purple-300 hover:ring-purple-600 dark:hover:ring-purple-300':
            props.severity === 'help' && !props.text && !props.outlined && !props.plain,
        },
        { 'focus:ring-purple-500 dark:focus:ring-purple-400': props.severity === 'help' },
        // Text & Outlined Button
        {
          'hover:bg-purple-300/20':
            (props.text || props.outlined) && props.severity === 'help' && !props.plain,
        },

        // Danger
        {
          'hover:bg-red-600 dark:hover:bg-red-300 hover:ring-red-600 dark:hover:ring-red-300':
            props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
        },
        { 'focus:ring-red-500 dark:focus:ring-red-400': props.severity === 'danger' },
        // Text & Outlined Button
        {
          'hover:bg-red-300/20':
            (props.text || props.outlined) && props.severity === 'danger' && !props.plain,
        },

        // Disabled
        { 'opacity-60 pointer-events-none cursor-default': context.disabled },

        // Transitions
        'transition duration-200 ease-in-out',

        // Misc
        'cursor-pointer overflow-hidden select-none',
      ],
    }),
    label: ({ props }) => ({
      class: [
        'duration-200',
        {
          'hover:underline': props.link,
        },
        { 'flex-1': props.label !== null, 'invisible w-0': props.label == null },
      ],
    }),
    icon: ({ props }) => ({
      class: [
        'mx-0',
        {
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null,
        },
      ],
    }),
    loadingicon: ({ props }) => ({
      class: [
        'h-3 w-3',
        'mx-0',
        {
          'mr-2': props.iconPos == 'left' && props.label != null,
          'ml-2 order-1': props.iconPos == 'right' && props.label != null,
          'mb-2': props.iconPos == 'top' && props.label != null,
          'mt-2': props.iconPos == 'bottom' && props.label != null,
        },
        'animate-spin',
      ],
    }),
    badge: ({ props }) => ({
      class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }],
    }),
  },
  calendar: {
    root: ({ props }) => ({
      class: [
        'inline-flex max-w-full relative',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
      ],
    }),
    input: {
      class: [
        'font-sans text-base text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 p-3 border border-gray-400 dark:border-blue-900/40 transition-colors duration-200 appearance-none',
        'hover:border-blue-500', //Hover
        'rounded-lg w-full',
      ],
    },
    panel: ({ props }) => ({
      class: [
        'bg-white dark:bg-gray-900',
        'min-w-[350px]',
        {
          'shadow-md border-0 absolute': !props.inline,
          'inline-block overflow-x-auto border border-gray-300 dark:border-blue-900/40 p-2 rounded-lg':
            props.inline,
        },
      ],
    }),
    header: {
      class: [
        'flex items-center justify-between',
        'p-2 text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 font-semibold m-0 border-b border-gray-300 dark:border-blue-900/40 rounded-t-lg',
      ],
    },
    dropdownbutton: 'rounded-l-none',
    previousbutton: {
      class: [
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
        'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
      ],
    },
    title: 'leading-8 mx-auto',
    monthTitle: {
      class: [
        'text-gray-700 dark:text-white/80 transition duration-200 font-semibold p-2',
        'mr-2',
        'hover:text-blue-500',
      ],
    },
    yearTitle: {
      class: [
        'text-gray-700 dark:text-white/80 transition duration-200 font-semibold p-2',
        'hover:text-blue-500',
      ],
    },
    nextbutton: {
      class: [
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
        'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
      ],
    },
    table: {
      class: ['border-collapse w-full', 'my-2'],
    },
    tableheadercell: 'p-2',
    weekday: 'text-gray-600 dark:text-white/70',
    day: 'p-2',
    daylabel: ({ context }) => ({
      class: [
        'w-10 h-10 rounded-full transition-shadow duration-200 border-transparent border',
        'flex items-center justify-center mx-auto overflow-hidden relative',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        {
          'opacity-60 cursor-default': context.disabled,
          'cursor-pointer': !context.disabled,
        },
        {
          'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80':
            !context.selected && !context.disabled,
          'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
        },
      ],
    }),
    monthpicker: 'my-2',
    month: ({ context }) => ({
      class: [
        'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
        'p-2 transition-shadow duration-200 rounded-lg',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        {
          'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80':
            !context.selected && !context.disabled,
          'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
        },
      ],
    }),
    yearpicker: {
      class: ['my-2'],
    },
    year: ({ context }) => ({
      class: [
        'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
        'p-2 transition-shadow duration-200 rounded-lg',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        {
          'text-gray-600 dark:text-white/70 bg-transprent hover:bg-gray-200 dark:hover:bg-gray-800/80':
            !context.selected && !context.disabled,
          'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
        },
      ],
    }),
    timepicker: {
      class: ['flex justify-center items-center', 'border-t-1 border-solid border-gray-300 p-2'],
    },
    separatorcontainer: 'flex items-center flex-col px-2',
    separator: 'text-xl',
    hourpicker: 'flex items-center flex-col px-2',
    minutepicker: 'flex items-center flex-col px-2',
    ampmpicker: 'flex items-center flex-col px-2',
    incrementbutton: {
      class: [
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
        'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
      ],
    },
    decrementbutton: {
      class: [
        'flex items-center justify-center cursor-pointer overflow-hidden relative',
        'w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
        'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
      ],
    },
    groupcontainer: 'flex',
    group: {
      class: [
        'flex-1',
        'border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0',
        'first:pl-0 first:border-l-0',
      ],
    },
    transition: TRANSITIONS.overlay,
    buttonBar: { class: ['flex justify-between p-1'] },
  },
  card: {
    root: {
      class: [
        //Shape
        'rounded-lg',
        'shadow-md',

        //Color
        'bg-white dark:bg-surface-900',
        'text-surface-700 dark:text-surface-0/80',
      ],
    },
    header: {
      class: ['border-b border-surface-200 dark:border-surface-700'],
    },
    body: {
      class: 'py-5',
    },
    title: {
      class: 'text-lg font-medium mb-2 px-5 md:px-6',
    },
    subtitle: {
      class: [
        //Spacing
        'mb-1 px-5 md:px-6',

        //Color
        'text-surface-600 dark:text-surface-0/60',
      ],
    },
    content: {
      class: 'py-6 px-5 md:px-6',
    },
    footer: {
      class: ['px-5 md:px-6 pt-5 pb-0', 'border-t border-surface-200 dark:border-surface-700'],
    },
  },
  checkbox: {
    root: {
      class: [
        'relative',

        // Alignment
        'inline-flex',
        'align-bottom',

        // Size
        'w-5',
        'h-5',

        // Misc
        'cursor-default',
        'select-none',
      ],
    },
    input: ({ props, context }) => ({
      class: [
        // Alignment
        'flex',
        'items-center',
        'justify-center',

        // Size
        'w-5',
        'h-5',

        // Shape
        'rounded',
        'border',

        // Colors
        'text-surface-600',
        {
          'border-surface-300 bg-medGray dark:border-surface-700 dark:bg-surface-900':
            !context.checked,
          'border-primary bg-primary dark:border-primary-400 dark:bg-primary-400': context.checked,
        },

        // States
        'focus:outline-none focus:outline-offset-0',
        {
          'ring-2 ring-primary-500 dark:ring-primary-400': !props.disabled && context.focused,
          'cursor-default opacity-60': props.disabled,
        },

        // Transitions
        'transition-colors',
        'duration-200',
      ],
    }),
    icon: {
      class: [
        // Font
        'text-normal',

        // Size
        'w-4',
        'h-4',

        // Colors
        'text-white dark:text-surface-900',

        // Transitions
        'transition-all',
        'duration-200',
      ],
    },
  },
  divider: {
    root: ({ props }) => ({
      class: [
        'flex relative', // alignments.
        {
          'w-full my-5 mx-0 py-0 px-5 before:block before:left-0 before:absolute before:top-1/2 before:w-full before:border-t before:border-gray-300 before:dark:border-blue-900/40':
            props.layout == 'horizontal', // Padding and borders for horizontal layout.
          'min-h-full mx-4 md:mx-5 py-5 before:block before:min-h-full before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2 before:border-l before:border-gray-300 before:dark:border-blue-900/40':
            props.layout == 'vertical', // Padding and borders for vertical layout.
        },
        {
          'before:border-solid': props.type == 'solid',
          'before:border-dotted': props.type == 'dotted',
          'before:border-dashed': props.type == 'dashed',
        }, // Border type condition.
      ],
    }),
    content: 'px-1 bg-white z-10 dark:bg-gray-900', // Padding and background color.
  },
  dropdown: {
    root: ({ props }) => ({
      class: [
        'cursor-pointer inline-flex relative select-none',
        'bg-white border border-gray-400 transition-colors duration-200 ease-in-out rounded-md',
        'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300',
        'w-full',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
      ],
    }),
    input: ({ props }) => ({
      class: [
        'cursor-pointer block flex flex-auto overflow-hidden text-ellipsis whitespace-nowrap relative',
        'bg-transparent border-0 text-gray-400',
        'dark:text-white/80',
        'p-3 transition duration-200 bg-transparent rounded appearance-none font-sans text-base',
        'focus:outline-none focus:shadow-none',
        { 'pr-7': props.showClear },
      ],
    }),
    trigger: {
      class: [
        'flex items-center justify-center shrink-0',
        'bg-transparent text-gray-500 w-12 rounded-tr-lg rounded-br-lg',
      ],
    },
    wrapper: {
      class: [
        'max-h-[200px] overflow-auto',
        'bg-white text-gray-700 border-0 rounded-md shadow-lg',
        'dark:bg-gray-900 dark:text-white/80',
      ],
    },
    list: {
      class: 'py-3 list-none m-0',
    },
    item: ({ context }) => ({
      class: [
        'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
        'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
        {
          'text-gray-700 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800':
            !context.focused && !context.selected,
          'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800':
            context.focused && !context.selected,
          'bg-blue-100 text-blue-700 dark:bg-blue-400 dark:text-white/80':
            context.focused && context.selected,
          'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80':
            !context.focused && context.selected,
        },
      ],
    }),
    itemgroup: {
      class: [
        'm-0 p-3 text-gray-800 bg-white font-bold',
        'dark:bg-gray-900 dark:text-white/80',
        'cursor-auto',
      ],
    },
    header: {
      class: [
        'p-3 border-b border-gray-300 text-gray-700 bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg',
        'dark:bg-gray-800 dark:text-white/80 dark:border-blue-900/40',
      ],
    },
    filtercontainer: {
      class: 'relative',
    },
    filterinput: {
      class: [
        'pr-7 -mr-7',
        'w-full',
        'font-sans text-base text-gray-700 bg-white py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
        'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300 dark:text-white/80',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
      ],
    },
    filtericon: {
      class: '-mt-2 absolute top-1/2',
    },
    clearicon: {
      class: 'text-gray-500 right-8 -mt-2 absolute top-1/2',
    },
    transition: TRANSITIONS.overlay,
  },
  fileupload: {
    input: 'hidden',
    buttonbar: {
      class: [
        'flex flex-wrap justify-between',
        'bg-gray-50 dark:bg-gray-800 p-5 border border-solid border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 rounded-tr-lg rounded-tl-lg gap-2 border-b-0',
      ],
    },
    cancelButton: {
      class: ['flex items-center'],
    },
    chooseButton: {
      class: [
        'text-white btn--primary bg-primary w-fit p-3 px-5 rounded-md text-base',
        'overflow-hidden relative',
      ],
    },
    chooseIcon: 'mr-2 inline-block',
    chooseButtonLabel: 'flex-1 font-bold',
    content: {
      class: [
        'relative',
        'bg-white dark:bg-gray-900 p-8 border border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 rounded-b-lg',
      ],
    },
    file: {
      class: [
        'flex items-center flex-wrap justify-between',
        'p-4 border border-gray-300 dark:border-blue-900/40 rounded gap-2 mb-2',
        'last:mb-0',
      ],
    },
    thumbnail: 'hidden',
    fileName: 'mb-2',
    fileSize: 'mr-2',
    uploadicon: 'hidden',
  },
  inputtext: {
    root: ({ props, context }) => ({
      class: [
        'm-0',
        'font-sans text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-400 dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-lg',
        {
          'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]':
            !context.disabled,
          'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
        },
        {
          'text-lg px-4 py-4': props.size == 'large',
          'text-xs px-2 py-2': props.size == 'small',
          'p-3 text-base': props.size == null,
        },
      ],
    }),
  },
  textarea: {
    root: ({ context }) => ({
      class: [
        'm-0',
        'font-sans text-base text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 p-3 border border-gray-300 dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-lg',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
      ],
    }),
  },
  multiselect: {
    root: ({ props }) => ({
      class: [
        'inline-flex cursor-pointer select-none',
        'bg-white dark:bg-gray-900 border border-gray-400 dark:border-blue-900/40  transition-colors duration-200 ease-in-out rounded-md',
        'w-full md:w-80',
        { 'opacity-60 select-none pointer-events-none cursor-default': props?.disabled },
      ],
    }),
    labelContainer: {
      class: 'overflow-hidden flex flex-auto cursor-pointer',
    },
    label: ({ props }) => ({
      class: [
        'block overflow-hidden whitespace-nowrap cursor-pointer text-ellipsis',
        'text-gray-400 dark:text-white/80',
        'p-3 transition duration-200',
        {
          '!p-3':
            props.display !== 'chip' &&
            (props?.modelValue == null || props?.modelValue == undefined),
          '!py-1.5 px-3': props.display == 'chip' && props?.modelValue !== null,
        },
      ],
    }),
    token: {
      class: [
        'py-1 px-2 mr-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white/80 rounded-full',
        'cursor-default inline-flex items-center',
      ],
    },
    removeTokenIcon: {
      class: 'ml-2',
    },
    trigger: {
      class: [
        'flex items-center justify-center shrink-0',
        'bg-transparent text-gray-600 dark:text-white/70 w-12 rounded-tr-lg rounded-br-lg',
      ],
    },
    panel: {
      class: [
        'bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border-0 rounded-md shadow-lg',
      ],
    },
    header: {
      class: [
        'p-3 border-b border-gray-300 dark:border-blue-900/40 text-gray-700 dark:text-white/80 bg-gray-100 dark:bg-gray-800 rounded-t-lg',
        'flex items-center justify-between',
      ],
    },
    headerCheckboxContainer: {
      class: ['inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6'],
    },
    headerCheckbox: ({ context }) => ({
      class: [
        'flex items-center justify-center',
        'border-2 w-6 h-6 text-gray-600 dark:text-white/70 rounded-lg transition-colors duration-200',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        {
          'border-gray-300 dark:border-blue-900/40 bg-white dark:bg-gray-900': !context?.selected,
          'border-blue-500 bg-blue-500': context?.selected,
        },
      ],
    }),
    headercheckboxicon: {
      class: 'w-4 h-4 transition-all duration-200 text-white text-base',
    },
    closeButton: {
      class: [
        'flex items-center justify-center overflow-hidden relative',
        'w-8 h-8 text-gray-500 dark:text-white/70 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
        'hover:text-gray-700 dark:hover:text-white/80 hover:border-transparent hover:bg-gray-200 dark:hover:bg-gray-800/80 ',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
      ],
    },
    closeButtonIcon: {
      class: 'w-4 h-4 inline-block',
    },
    wrapper: {
      class: [
        'max-h-[200px] overflow-auto',
        'bg-white text-gray-700 border-0 rounded-md shadow-lg',
        'dark:bg-gray-900 dark:text-white/80',
      ],
    },
    list: {
      class: 'py-3 list-none m-0',
    },
    item: ({ context }) => ({
      class: [
        'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
        'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
        {
          'text-gray-700 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800':
            !context.focused && !context.selected,
          'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800':
            context.focused && !context.selected,
          'bg-blue-100 text-blue-700 dark:bg-blue-400 dark:text-white/80':
            context.focused && context.selected,
          'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:text-white/80':
            !context.focused && context.selected,
        },
      ],
    }),
    checkboxContainer: {
      class: ['inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6'],
    },
    checkbox: ({ context }) => ({
      class: [
        'flex items-center justify-center',
        'border-2 w-6 h-6 text-gray-600 dark:text-white/80 rounded-lg transition-colors duration-200',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
        {
          'border-gray-300 dark:border-blue-900/40  bg-white dark:bg-gray-900': !context?.selected,
          'border-blue-500 bg-blue-500': context?.selected,
        },
      ],
    }),
    checkboxicon: {
      class: 'w-4 h-4 transition-all duration-200 text-white text-base',
    },
    itemgroup: {
      class: [
        'm-0 p-3 text-gray-800 bg-white font-bold',
        'dark:bg-gray-900 dark:text-white/80',
        'cursor-auto',
      ],
    },
    filtercontainer: {
      class: 'relative',
    },
    filterinput: {
      class: [
        'pr-7 -mr-7',
        'w-full',
        'font-sans text-base text-gray-700 bg-white py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none',
        'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300 dark:text-white/80',
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
      ],
    },
    filtericon: {
      class: '-mt-2 absolute top-1/2',
    },
    clearicon: {
      class: 'text-gray-500 right-12 -mt-2 absolute top-1/2',
    },
    transition: TRANSITIONS.overlay,
  },
  chip: {
    root: {
      class: ['inline-flex items-center', 'rounded-[16px] px-3 mr-1 text-sm whitespace-nowrap'],
    },
    label: 'leading-6 mt-1 mb-1',
    icon: 'leading-6 mr-2',
    image: {
      class: ['w-9 h-9 ml-[-0.75rem] mr-2', 'rounded-full'],
    },
    removeIcon: {
      class: ['ml-2 rounded-md transition duration-200 ease-in-out', 'cursor-pointer leading-6'],
    },
  },
}
