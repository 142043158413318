<template>
  <Transition
    enter-active-class="transition-opacity duration-500"
    leave-active-class="transition-opacity duration-500"
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
  >
    <div
      v-if="user && showUpsell && !isSubscribed"
      class="flex flex-col bg-white items-center w-full h-full fixed z-20 p-24 overflow-scroll"
    >
      <template v-if="!showStripe">
        <Card>
          <template #content>
            <div class="flex flex-col bg-white items-center">
              <div class="font-bold font-montserrat text-3xl text-center">
                Grain Fertility Premium
              </div>
              <div class="text-center py-4 text-sm">
                Access to
                <li>1:1 Expert Coaching and Guidance</li>
                <li>Resources and Tools Timed For Where You Are On Your Journey</li>
                <li>Discounts and Offers from Grain Fertility Partners</li>
                <li>Exclusive Live Community Events</li>
                <li>Create Custom Organizational Labels</li>
                <li>
                  Ability to Share Information with Spouse, Partner, Care Team, Or Anyone You Choose
                </li>
                <li>Personal Fertility Expense Tracking Tool</li>
                <p class="mt-6">Two weeks for free, then less than a dollar a day.</p>
              </div>
              <div class="font-montserrat pb-2">
                <span class="text-primary text-3xl font-bold">$12.99</span
                ><span class="text-xl">/mo</span>
              </div>
              <div class="text-sm">After free trial</div>
              <Button
                @click="
                  () => {
                    showStripe = true
                  }
                "
                class="btn--primary bg-primary my-6 font-montserrat w-auto p-5"
              >
                <span>Start my</span>&nbsp;<span class="font-bold">Premium Free Trial</span>
              </Button>
              <Button class="text-sm" link @click="hideOverlay()" label="Ask me later"></Button>
            </div>
          </template>
        </Card>
      </template>
      <template v-if="user && showStripe && !isSubscribed">
        <StripeForm @cancel="hideStripe" @subscribed="hideOverlay" />
      </template>
      <img
        src="@/assets/images/upsell.png"
        alt="Grain Fertility Background"
        class="absolute bottom-0 right-0 -z-10"
      />
    </div>
  </Transition>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import Card from 'primevue/card'
import Button from 'primevue/button'
import StripeForm from './StripeForm.vue'

const store = useStore()
const showStripe = ref(false)
const hideStripe = () => (showStripe.value = false)
const showUpsell = computed(() => store.getters.showUpsell)
const isSubscribed = computed(() => store.getters.user?.isSubscribed)
const user = computed(() => store.getters.user)
const hideOverlay = () => {
  store.dispatch('setShowUpsell', false)
  hideStripe()
}
</script>
