import { createApi, createCustomServiceCall } from '@thinknimble/tn-models'
import { z } from 'zod'
import axiosInstance from '../AxiosClient'
import {
  userShape,
  userUpdateShape,
  forgotPasswordShape,
  userCreateShape,
  loginShape,
} from './models'

const login = createCustomServiceCall(
  {
    inputShape: loginShape,
    outputShape: userShape,
  },
  async ({ client, input, utils }) => {
    const res = await client.post('/login/', utils.toApi(input))
    return utils.fromApi(res.data)
  },
)

const requestPasswordReset = createCustomServiceCall(
  {
    inputShape: forgotPasswordShape,
  },
  async ({ client, input }) => {
    await client.post(`/password/reset/`, { email: input.email })
  },
)
const resetPassword = createCustomServiceCall(
  {
    inputShape: { uid: z.string(), token: z.string(), password: z.string() },
    outputShape: userShape,
  },
  async ({ client, input, utils }) => {
    const data = utils.toApi(input)
    const res = await client.post(`/password/reset/confirm/`, data)
    return utils.fromApi(res.data)
  },
)

const customUpdate = createCustomServiceCall(
  {
    inputShape: userUpdateShape,
    outputShape: userShape,
  },
  async ({ client, input, utils: { toApi, fromApi } }) => {
    const { id, ...rest } = toApi(input)
    const res = await client.patch(`/users/${id}/`, rest)
    return fromApi(res.data)
  },
)

export const userApi = createApi(
  {
    client: axiosInstance,
    baseUri: '/users/',
    models: {
      create: userCreateShape,
      entity: userShape,
    },
  },
  { login, requestPasswordReset, resetPassword, customUpdate },
)
