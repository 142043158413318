import axiosInstance from '../AxiosClient'

export const getProductsWithPrices = async (paymentMethodId) => {
  const res = await axiosInstance.get('/stripe/products/', { payment_method_id: paymentMethodId })
  return res.data['products'][0]['prices'][0]['unit_amount']
}

export const updatePaymentMethod = async (paymentMethodId) => {
  return await axiosInstance.post('/stripe/payment/', { payment_method_id: paymentMethodId })
}

export const createSubscription = async (paymentMethodId) => {
  return await axiosInstance.post('/stripe/subscription/', { payment_method_id: paymentMethodId })
}

export const cancelSubscription = async (paymentMethodId) => {
  return await axiosInstance.delete('/stripe/subscription/')
}
